export default {
  aboutUs: 'About us',
	appLogo:
		'Logo for Alfred, showing a cordless phone lying on its back with two ovals drawn on top of it to resemble a smiling face.',
	allRightsReserved: 'All rights reserved.',
	chatInstructions:
		'Experience Alfred in Action!\n\nSend a text or WhatsApp message to the number below, or chat directly on our website to interact with Alfred, our AI-powered assistant. Alfred can answer questions about our product, provide you with business information, and demonstrate how easy it is to book services like house cleaning, moving, and more.\n\nNote: This is a test run—no actual reservation will be made. Discover how Alfred can simplify service reservations for your customers!',
    chatWithAlfred: 'Chat With Alfred',
    chatWithAlfredNow: 'Chat With Alfred Now!',
	close: 'Close',
	companyProfileDescription: 'We\'re bringing an end to step zero churn and resource-intensive lead meetings.',
	contactUs: 'Questions? Contact an AI',
	continue: 'Continue',
	emailCannotBeEmpty: 'Email cannot be empty.',
	emailTooLong: 'The input is too long for a valid email address.',
	enterEmailInstructions:
		'To reveal the phone number for our AI support agent, please enter your email address below.',
	featureDashboardDescription: 'Access a lightweight CRM dashboard where you can find your leads\' profiles, service requests, and transcripts of their conversations. Alfred automatically populates this data in real time.',
	featureDashboardHeader: 'Everything you need to know about your leads in one place',
	featureChatbotDescription: 'Let your clients speak to Alfred via SMS, WhatsApp, or as a chatbot embed on your website. Alfred will answer their queries and record their service requests so you and your staff don\'t have to.',
	featureChatbotHeader: 'Get the initial conversation rolling anytime, anywhere',
  footerAppDescription: 'Alfred simplifies customer inquiry and request management by providing an endlessly knowledgeable digital guru who always delivers peak performance.',
  footerCopyright: '©2024 Averi Studios Inc. All rights reserved',
  footerHeader: 'Keeping up with quotes and inquiries doesn\'t need to be expensive or time-consuming.',
  footerJoinWaitlistText: 'Don’t miss out on the opportunity to elevate your business’s customer communications. Join the waitlist for Alfred today and be first in line for a smarter and more satisfying customer experience.',
  footerTagline: 'Alfred is your business\'s tireless leads agent, responding to every lead with unmatched speed and intuitiveness to maximize conversions.',
	keepTrackOfLeads: 'Leads and their requests collected in one place',
	tagline: 'Instantly boost customer satisfaction with Alfred, your 24/7 digital assistant, expertly handling inquiries and delivering fast results.',
	header: 'Your leads’ tireless agent',
	heroTagline: 'Need to keep up with your leads’ quotes and inquiries? Get Alfred to provide instant responses and track service requests for any number of leads.',
	introText:
		'Meet Alfred: an always-available guru who can effortlessly handle any number of calls about your business’s products, services, hours of operation, and more.',
	introButtonText: 'Try calling an AI!',
	invalidEmailAddress: 'Please enter a valid email address.',
  joinWaitlist: 'Join Waitlist',
	landingPageTitle: 'The Helpful and Tirelessly Dedicated Digital Guru for Your Business',
	leadsAndRequestsDashboardDescription: 'Easily view all leads’ contact info and requests in a lightweight CRM.',
	leadsAndRequestsDashboardTitle: 'Leads & requests dashboard',
	openInWhatsApp: 'Open in WhatsApp',
	ourCompany: 'Our Company',
	overviewHeader: 'Answer every lead instantly.',
	overviewSubtitle: 'Say goodbye to wait queues and churn at step zero.',
	pitchHeader: 'Entrust leads to an always-available agent to',
	pitchHeaderEmphasized: 'guarantee conversions',
	pitchTagline: 'By facilitating quick and informative conversations, Alfred maximizes your potential to convert leads into clients.',
	instantResponseTimes: 'Instant response times to eliminate waiting queue churn',
	realTimeConversationsDescription: 'Respond to leads with friendly conversations without any wait time.',
	realTimeConversationsTitle: 'Real-time conversations',
	saveOnCustomerServiceResources: 'Significant savings on customer service resources',
	seeHowAlfredWorksChatNow: 'See how Alfred works—chat now!',
	scaleEffortlesslyTitle: 'Effortless scalability',
	scaleEffortlesslyDescription: 'Handle any number of simultaneous conversations with 24/7 availability.',
	summary:
		"Use AI to efficiently and accurately answer customer support queries. We know how difficult it can be to scale customer support resources to keep up with the growth of your products. That's why caller queues and unhappy customers will soon be a thing of the past as AI paves the path for intelligent answers that will always keep up with your user base.",
	unexpectedError: 'An unexpected error occurred. Please try again later.',
	valuePropositionSubtitle: 'Guarantee Customer Satisfaction',
	valuePropositionTitle: 'Make Your Customer Experience Rock-Solid',
  viewDemoMenu: 'View Demo Restaurant Menu',
	whyWeCollectInfo:
		'We collect this information in order to gauge interest and provide you with updates and offers.',
};
